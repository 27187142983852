export const paginationItemStyles = (isRtl: boolean, slideDuration: number) => ({
	'sx': {
		"@keyframes slideProgress": {
			"0%": {
				transform: isRtl ? "translateX(100%)" : "translateX(-100%)",
			},
			"50%": {
				transform: "translateX(0%)",
			},
			"100%": {
				transform: isRtl ? "translateX(-100%)" : "translateX(100%)",
			}
		},
		userSelect: "none",
		width: "100%",
		textAlign: "start",
		color: "white",
		'.pagination__progress': {
			position: ["relative", null, "absolute"],
			overflow: "hidden",
			bottom: "0",
			zIndex: "-1",
			width: "100%",
			h: "1",
			background: ["whiteAlpha.400", null, "whiteAlpha.200"],
			"&:after": {
				content: "''",
				display: "block",
				position: "absolute",
				bottom: "0",
				w: "100%",
				h: "100%",
				background: "gradients.progress",
				transform: isRtl ? "translateX(100%)" : "translateX(-100%)",
				animationTimingFunction: "ease-in-out",
				animationDuration: `${slideDuration / 1000 - .4}s`,
				animationDelay: ".4s",
			},
			'li.is-active &': {
				"&:after": {
					animationName: "slideProgress",
				}
			}
		}
	}
})