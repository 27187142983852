import React, { FC } from "react";
// eslint-disable-next-line no-restricted-imports
import { Text as ChakraText, Box, Heading, Flex, Image as ChakraImage } from "@chakra-ui/react";
import { mediaApi, sc, useSitecoreContext } from "~/foundation/Jss";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { HeroCarouselSlide } from "../generated-types";
import { imageSizes } from "~/foundation/Theme";
import { Video } from "~/foundation/Components/Video";

type HeroCarouselItemProps = {
	slide: HeroCarouselSlide;
	renderVideo: boolean;
	setSplideAutoplay: React.Dispatch<React.SetStateAction<boolean>>;
	index: number;
	numberOfSlides: number;
	playing?: boolean;
}

export const HeroCarouselItem: FC<HeroCarouselItemProps> = ({ slide, renderVideo, setSplideAutoplay, playing, index, numberOfSlides }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<Box
			position="relative"
			h="100%"
			color="white">
			<Box className="slide__background">
				{slide.backgroundVideo?.value?.src && renderVideo ?
					<Video
						src={slide.backgroundVideo.value.src}
						preload="yes"
						className="slide__video"
						autoPlay
						playing={playing}
						muted
						loop
						playsInline
						style={{ position: "absolute", objectFit: "cover", objectPosition: "center center", minWidth: "100%", minHeight: "100%" }} />
					:
					<>
						{slide.backgroundImage?.value?.src &&
							<ChakraImage
								src={mediaApi.updateImageUrl(slide.backgroundImage.value.src, imageSizes.none)}
								sizes="100vw"
								data-splide-lazy={slide.backgroundImage.value.src}
								data-splide-lazy-srcset={mediaApi.getSrcSet(slide.backgroundImage.value.src, [imageSizes.heroCarousel.s, imageSizes.heroCarousel.m, imageSizes.heroCarousel.l, imageSizes.heroCarousel.xl])}
								alt={slide.backgroundImage.value.alt}
							/>
						}
					</>
				}
			</Box>
			<ContentWrapper
				className="slide__content"
				h="100%"
			>
				<Flex
					position="relative"
					direction="column" h="100%"
					justifyContent="center"
					maxW="790px"
				>
					{slide.trumpet &&
						<Box
							className="slide__trumpet"
							mb="4">
							{
								slide.primaryLink?.value
									?
									<sc.Link field={slide.primaryLink} showLinkTextWithChildrenPresent={false} editable={false}>
										<ChakraText
											variant="overline"
											lineHeight={isRtl ? ['1.75rem', null, null, "2rem"] : ['1rem', null, null, "1.125rem"]}
											onMouseEnter={() => setSplideAutoplay(false)}
											onMouseLeave={() => setSplideAutoplay(true)}>
											<sc.Text field={slide.trumpet} />
										</ChakraText>
									</sc.Link>
									:
									<ChakraText variant="overline">
										<sc.Text field={slide.trumpet} />
									</ChakraText>
							}
						</Box>
					}
					{slide.headline?.value &&
						<span
							className="slide__headline">
							{
								slide.primaryLink?.value
									?
									<sc.Link field={slide.primaryLink} showLinkTextWithChildrenPresent={false} editable={false} dataLayer={() => ({
										event: "GAevent",
										event_type: "click",
										event_name: "homepage_hero_carousel",
										text: slide.headline?.value,
										index: index,
										count: numberOfSlides,
										type: "primary",
										url: slide.primaryLink?.value.href
									})}>
										<Heading
											as="h2"
											size="xl"
											pb="2"
											mb={["4", null, "2"]}
											noOfLines={4}
											onMouseEnter={() => setSplideAutoplay(false)}
											onMouseLeave={() => setSplideAutoplay(true)}
											wordBreak="normal">
											<sc.Text field={slide.headline} />
										</Heading>
									</sc.Link>
									:
									<Heading as="h2" size="xl" mb={["6", null, "4"]} noOfLines={4} wordBreak="normal">
										<sc.Text field={slide.headline} />
									</Heading>
							}
						</span>
					}
					{(slide.primaryLink || slide.secondaryLink) &&
						<Flex
							wrap="wrap"
							direction={["column", null, "row"]}
							className="slide__links">
							<>
								{slide.primaryLink?.value.href &&
									<Box
										me={[null, null, "16"]}
										mt={[null, null, "4"]}
										className="slide__link"
										onMouseEnter={() => setSplideAutoplay(false)}
										onMouseLeave={() => setSplideAutoplay(true)}>
										<sc.Link field={slide.primaryLink} editable={false} variant="primary" size={["md", null, "lg"]} color="white" dataLayer={() => ({
											event: "GAevent",
											event_type: "click",
											event_name: "homepage_hero_carousel",
											text: slide.headline?.value,
											index: index,
											count: numberOfSlides,
											type: "primary",
											url: slide.primaryLink?.value.href
										})} />
									</Box>
								}
								{slide.secondaryLink?.value.href &&
									<Box
										mt={["6", null, "4"]}
										display="flex"
										className="slide__link"
										onMouseEnter={() => setSplideAutoplay(false)}
										onMouseLeave={() => setSplideAutoplay(true)}>
										<sc.Link field={slide.secondaryLink} editable={false} variant="secondary" size={["md", null, "lg"]} color="white" dataLayer={() => ({
											event: "GAevent",
											event_type: "click",
											event_name: "homepage_hero_carousel",
											text: slide.headline?.value,
											index: index,
											count: numberOfSlides,
											type: "secondary",
											url: slide.secondaryLink?.value.href
										})} />
									</Box>
								}
							</>
						</Flex>
					}
				</Flex>
			</ContentWrapper>
		</Box>
	);
};
