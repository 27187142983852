import React, { FC } from "react";
import {
	AspectRatio,
	Box,
	Collapse,
	Text as ChakraText,
	// eslint-disable-next-line no-restricted-imports
	Image as ChakraImage,
	useMediaQuery
} from "@chakra-ui/react";
import { HeroCarouselSlide } from "../../generated-types";
import { imageSizes } from "~/foundation/Theme";
import { paginationItemStyles } from "./styles";
import { mediaApi, useSitecoreContext } from "~/foundation/Jss";
import { Video } from "~/foundation/Components/Video";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

type PaginationProps = {
	slide: HeroCarouselSlide;
	slideDuration: number;
	isActive: boolean;
	isPlaying: boolean;
	itemId?: string;
};

export const PaginationItem: FC<PaginationProps> = ({ slide, slideDuration, isActive, isPlaying }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [showPaginationImage, setShowPaginationImage] = React.useState(false);
	const togglePaginationImage = () => setShowPaginationImage(!showPaginationImage);
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`);
	const isRtl = sitecoreContext.custom.settings.isRtl;

	return (
		<Box
			{...paginationItemStyles(isRtl,slideDuration)}
			onMouseEnter={togglePaginationImage}
			onMouseLeave={togglePaginationImage}>
			{slide.trumpet &&
				<ChakraText
					variant="overline"
					noOfLines={1}
					fontSize="2xs"
					fontWeight="600"
					lineHeight={isRtl ? "2rem" : "1rem"}
					letterSpacing={isRtl ? "0" : ".1em"}
					display={["none", null, "block"]}
					mb="4"
					transition="opacity .5s"
					opacity={isActive || showPaginationImage ? "1" : ".5"}>
					{slide.trumpet?.value}
				</ChakraText>
			}
			<Box display={isMdBreakpointOrAbove ? "block" : "none"}>
				<Collapse
					in={!isActive ? showPaginationImage : false}
					transition={{
						enter: {
							ease: "easeOut",
							duration: .6
						},
						exit: {
							ease: "easeOut",
							duration: .4
						}
					}}
				>
					<AspectRatio ratio={16 / 9} minWidth="100%">
						{slide.backgroundVideo?.value?.src && isMdBreakpointOrAbove ?
							<Video
								src={slide.backgroundVideo.value.src}
								autoPlay
								muted
								loop
								style={{ maxWidth: "100%", borderRadius: "var(--chakra-radii-sm)", borderBottomRightRadius: "0" }} />
							:
							<>
								{slide.backgroundImage?.value?.src && isMdBreakpointOrAbove && (
									<ChakraImage
										src={mediaApi.updateImageUrl(slide.backgroundImage.value.src, imageSizes.heroCarousel.xs)}
										alt={slide.backgroundImage.value.alt}
										sx={{ maxWidth: "100%", borderRadius: "sm", borderBottomRightRadius: "0" }}
									/>
								)}
							</>
						}
					</AspectRatio>
				</Collapse>
			</Box>
			{(!showPaginationImage || isActive || !isMdBreakpointOrAbove) &&
				<Box className="pagination__progress" borderRadius="xs" sx={{ '&:after': { animationPlayState: isPlaying ? "running" : "paused" } }} />
			}
		</Box>
	);
};
