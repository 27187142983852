import React, { FC, useEffect } from "react";

type VideoProps = Omit<JSX.IntrinsicElements["video"], "ref"> & {
	src: string | [string, string][];
	playing?: boolean;
}

export const Video: FC<VideoProps> = ({ src, playing = true, ...rest }) => {
	const videoRef = React.createRef<HTMLVideoElement>();

	useEffect(() => {
		if (videoRef?.current) {
			if (playing) {
				if (videoRef.current.paused) {
					videoRef.current.play();
				}
			} else {
				if (!videoRef.current.paused) {
					videoRef.current.pause();
				}
			}
		}
	}, [playing, videoRef])

	return (
		<video {...rest} ref={videoRef}>
			{typeof src === "string" && (
				<>
					<source src={src} type="video/webm" />
					<source src={src + "?extension=mp4"} type="video/mp4" />
				</>
			)}
			{Array.isArray(src) && src.map(([src, type], index) => (
				<source key={index} src={src} type={type} />
			))}
		</video>
	)
};