export const heroCarouselStyles = (isRtl: boolean) => ({
	'sx': {
		"@keyframes revealBelow": {
			from: {
				height: "100dvh"
			},
			to: {
				height: "95dvh",
			}
		},
		'.slide__wrapper': {
			height: "100dvh",
			animationName: "revealBelow",
			animationDuration: "1s",
			animationDelay: "2s",
			animationFillMode: "forwards",
			animationTimingFunction: "cubic-bezier(.23,1.76,.47,1.09)",
			overflow: "hidden",
			'.splide__track': {
				height: "100dvh",
			},
			'.splide__slide': {
				//Bug with splidejs direction in combination with fade not working
				transform: isRtl ? "translateX(0%) !important" : "",
				'&:nth-of-type(2)': {
					transform: isRtl ? "translateX(100%) !important" : "",
				},
				'&:nth-of-type(3)': {
					transform: isRtl ? "translateX(200%) !important" : "",
				},
				'&:nth-of-type(4)': {
					transform: isRtl ? "translateX(300%) !important" : "",
				},
				'.slide__background': {
					"img": {
						position: "absolute",
						objectFit: "cover",
						objectPosition: "center center",
						minWidth: "100%",
						minHeight: "100%",
						transform: "scale(1.03)",
						transformOrigin: "top right",
						transition: "transform .8s ease-out",
					},
					"&:after": {
						content: `''`,
						position: 'absolute',
						top: '0',
						right: '0',
						bottom: '0',
						left: '0',
						background: ["linear-gradient(69.09deg, rgba(0, 0, 0, 0.76) 31.16%, rgba(0, 0, 0, 0) 90.51%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 27.65%)", null, null, "linear-gradient(81.38deg, rgba(0, 0, 0, 0.8075) 34.59%, rgba(0, 0, 0, 0) 71.8%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 27.65%)", null, "linear-gradient(72.77deg, rgba(0, 0, 0, 0.855) 26.39%, rgba(0, 0, 0, 0) 83.78%, rgba(0, 0, 0, 0.27) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 27.65%)"],
						transform: isRtl ? "scaleX(-1)" : "",
					}
				},
				'.slide__content': {
					pt: ["32", null, "7.75rem", null, "7.75rem"],
					pb: ["32", null, "7.75rem", null, "7.75rem"],

					"@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1200px), only screen and (min--moz-device-pixel-ratio: 1.5) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 1.5/1) and (min-width: 1200px), only screen and (min-device-pixel-ratio: 1.5) and (min-width: 1200px)": {
						pt: "3.75rem",
						pb: "3.75rem",
					},

					'.slide__trumpet, .slide__headline, .slide__links': {
						display: "inline-flex",
						overflow: "hidden",

						"@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1200px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1200px), only screen and (min-device-pixel-ratio: 2) and (min-width: 1200px)": {
							overflow: "visible"
						},
					},
					'p': {
						transform: "translate3d(2px,41px,0)",
						transformOrigin: "center center",
						transition: "transform .75s .1s ease-in-out",
						willChange: "transform",
						backfaceVisibility: "hidden",
					},
					'h2': {
						transform: "translate3d(5px,150px,0)",
						transformOrigin: "center center",
						transition: "transform .75s .1s ease-in-out",
						willChange: "transform",
						backfaceVisibility: "hidden",
					},
					'.slide__link': {
						transform: "translateY(90px)",
						transformOrigin: "center center",
						transition: "transform .75s .1s ease-in-out",
						willChange: "transform",
						backfaceVisibility: "hidden",
					}
				},
				'&.is-visible': {
					'.slide__content': {
						'p, h2': {
							transform: "translate3d(0,0,0)",
						},
						'.slide__link': {
							transform: "translateY(0)"
						}
					},
					"img": {
						transform: "scale(1)",
					}
				}
			},
		},
		'.pagination__wrapper': {
			position: "absolute",
			bottom: ["8", null, "12"],
			w: "100%",
			'.splide__list': {
				alignItems: "flex-end",
				justifyContent: "center",
				gap: ["0", null, "5"],
				'.splide__slide': {
					w: ["2.625rem", null, "calc(25% - .9375rem)"],
					flexGrow: [0],
					transition: 'width .3s ease-in-out',
					'+ .splide__slide': {
						ms: ["2", null, "0"],
					},
					'&.is-active': {
						w: ["12rem", null, "calc(25% - .9375rem)"],
						flexShrink: [1, null, 0],
					}
				}
			}
		}
	}
})