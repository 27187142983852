import React, { FC, useEffect, useState } from "react";
import { HeroCarouselProps, HeroCarouselSlide } from "./../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { HeroCarouselItem } from "./HeroCarouselItem";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { Splide, SplideSlide, SplideTrack, Options, SplideProps } from "@splidejs/react-splide"
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { heroCarouselStyles } from "./styles";
import { PaginationItem } from "./PaginationItem";
import { mediaApi, useSitecoreContext } from "~/foundation/Jss";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { BreakingNews } from "~/foundation/Components/BreakingNews";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";
import { Helmet } from "react-helmet-async";
import { imageSizes } from "~/foundation/Theme";

export const HeroCarousel: FC<HeroCarouselProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [splideAutoplay, setSplideAutoplay] = useState(true);
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`);
	const mainRef = React.createRef<Splide>();
	const paginationRef = React.createRef<Splide>();
	const slideDuration = 8000;
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const paginationOptions: Options = {
		arrows: false,
		pagination: false,
		autoWidth: true,
		isNavigation: true,
		drag: false,
		mediaQuery: 'min',
		direction: isRtl ? "rtl" : "ltr",
		breakpoints: {
			768: {
				perPage: 4,
			}
		},
	}

	const sliderOptions: Options = {
		type: "fade",
		lazyLoad: "nearby",
		interval: slideDuration,
		speed: 1500,
		arrows: false,
		pagination: false,
		direction: isRtl ? "rtl" : "ltr",
		autoHeight: true,
		width: "100vw",
		autoplay: true,
		rewind: true,
		pauseOnHover: false,
		pauseOnFocus: false,
		resetProgress: false,
	}

	const slides = rendering.customData?.slides?.slice(0, 4) || [];

	//const slidesOld = fields?.slides?.slice(0, 4) || [];
	const numberOfSlides = slides.length < 4 ? slides.length : 4;

	useEffect(() => {
		if (paginationRef.current?.splide) {
			mainRef.current?.sync(paginationRef.current.splide);
		}

		if (mainRef.current?.splide) {
			const splide = mainRef.current?.splide;
			const AutoPlay = splide.Components.Autoplay;

			if (splideAutoplay) {
				AutoPlay?.play();
			} else {
				AutoPlay?.pause();
			}
		}
	}, [mainRef, paginationRef, splideAutoplay]);

	const slidesWithVideos = slides.slice(0, 4).map((slide: HeroCarouselSlide, index) => {
		const hasVideo = slide.backgroundVideo?.value?.src;
		if (hasVideo) {
			return index;
		}

		return null;
	}).filter(x => x !== null);

	const paginationItemClick: SplideProps["onClick"] = (splide, Slide) => {

		const activeSlide = splide.splides[Slide.index];

		const root = activeSlide?.splide.root;
		const text = root?.querySelector("h2")?.innerText || Slide.slide?.querySelector("h2")?.innerText;
		if (!text) {
			return;
		}

		pushDataLayer(() => ({
			event: "GAevent",
			event_type: "click",
			event_name: 'homepage_hero_carousel',
			type: "slide up",
			text,
			count: numberOfSlides,
			index: Slide.index
		}));
	}

	return (
		<Ribbon position="relative" {...heroCarouselStyles(isRtl)}>
			<BreakingNews sitecoreContext={sitecoreContext} />
			<Splide ref={mainRef} options={sliderOptions} hasTrack={false} className="slide__wrapper">
				<SplideTrack>
					{slides.map((homepageCarouselItem: HeroCarouselSlide, index) => {
						const foundVideo = slidesWithVideos?.find(indexWithVideo => {
							const lastIndex = (slides || []).slice(0, 4).length - 1;

							if (indexWithVideo === activeIndex) {
								return true;
							}

							if (indexWithVideo === activeIndex - 1) {
								return true;
							}

							if (indexWithVideo === activeIndex + 1) {
								return true;
							}

							if (activeIndex === 0) {
								if (indexWithVideo === lastIndex) {
									return true;
								}
								if (indexWithVideo === activeIndex + 1) {
									return true;
								}
							}

							if (activeIndex === lastIndex) {
								if (indexWithVideo === activeIndex - 1) {
									return true;
								}
								if (indexWithVideo === 0) {
									return true;
								}
							}

							return false;
						});

						const trackingData = {
							type: "auto slide",
							text: homepageCarouselItem.headline?.value,
							index: index + 1,
							count: numberOfSlides
						};

						if (typeof foundVideo !== "undefined") {
							return (
								<SplideSlide key={`hero_carousel_item_${index}`} data-tracking-type="visibility" data-tracking-id="homepage_hero_carousel" data-tracking-data={JSON.stringify(trackingData)}>
									<HeroCarouselItem index={index} slide={homepageCarouselItem} renderVideo={true} setSplideAutoplay={setSplideAutoplay} playing={splideAutoplay} numberOfSlides={numberOfSlides} />
								</SplideSlide>
							);
						}

						return (
							<React.Fragment key={`hero_carousel_item_${index}`}>
								{index === 0 &&
									<Helmet>
										<link rel="preload" as="image" href={mediaApi.updateImageUrl(homepageCarouselItem.backgroundImage?.value?.src, imageSizes.none)} imageSrcSet={mediaApi.getSrcSet(homepageCarouselItem.backgroundImage?.value?.src, [imageSizes.heroCarousel.s, imageSizes.heroCarousel.m, imageSizes.heroCarousel.l, imageSizes.heroCarousel.xl])} />
									</Helmet>
								}
								<SplideSlide data-tracking-type="visibility" data-tracking-id="homepage_hero_carousel" data-tracking-data={JSON.stringify(trackingData)}>
									<HeroCarouselItem index={index} slide={homepageCarouselItem} renderVideo={false} setSplideAutoplay={setSplideAutoplay} numberOfSlides={numberOfSlides} />
								</SplideSlide>
							</React.Fragment>
						);
					})}
				</SplideTrack>
			</Splide>
			<Box className="pagination__wrapper">
				<ContentWrapper py="0" px={["8", null, null, null, "16"]}>
					<Splide ref={paginationRef} options={paginationOptions} hasTrack={false} onMoved={(data, newIndex) => setActiveIndex(newIndex as number)} onClick={paginationItemClick}>
						<SplideTrack>
							{slides.map((homepageCarouselItem: HeroCarouselSlide, index) => {

								const trackingData = {
									type: "hover slide up",
									text: homepageCarouselItem.headline?.value,
									index: index,
									count: numberOfSlides
								};

								return (
									<SplideSlide
										key={index}
										onMouseEnter={() => setSplideAutoplay(isMdBreakpointOrAbove ? false : true)}
										onMouseLeave={() => setSplideAutoplay(true)}
										data-tracking-type="visibility"
										data-tracking-id="homepage_hero_carousel"
										data-tracking-data={JSON.stringify(trackingData)}>
										<PaginationItem
											slide={homepageCarouselItem}
											slideDuration={slideDuration}
											isActive={index === activeIndex}
											isPlaying={splideAutoplay}/>
									</SplideSlide>
								)
							})}
						</SplideTrack>
					</Splide>
				</ContentWrapper>
			</Box>
		</Ribbon>
	);
};
