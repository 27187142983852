import React, { FC } from "react";
import { JssField, LinkField, SitecoreContextValue, TextField } from "~/foundation/Jss";
import { Box, Flex, Text as ChakraText } from "@chakra-ui/react";
import { Ribbon } from "../Ribbon";
import { ContentWrapper } from "../ContentWrapper";
import { sizes } from "~/foundation/Theme/variables/sizes";
import { animations } from "~/foundation/Theme/styles/animations";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";
import { ChakraRouterLink } from "../Link/ChakraRouterLink";

type BreakingNewsAlert = {
	enable?: JssField<boolean>;
	label?: TextField;
	link?: LinkField;
	text?: TextField;
}

type BreakingNewsProps = {
	sitecoreContext: SitecoreContextValue
};

export const BreakingNews: FC<BreakingNewsProps> = ({ sitecoreContext }) => {
	const breakingNews = sitecoreContext.custom.settings.breakingNews as BreakingNewsAlert | undefined;
	if (!breakingNews || !breakingNews.enable?.value || !breakingNews.text?.value) {
		return <></>;
	}

	const breakingNewsLink = breakingNews.link?.value?.href;
	const hasDynamicHeaderBackground = sitecoreContext.custom.hasDynamicHeaderBackground;
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const theme = usePageTheme(sitecoreContext);
	const { colors } = theme;

	return (
		<Ribbon
			position={hasDynamicHeaderBackground ? "absolute" : ["relative", null, null, "absolute"]}
			zIndex={["1", null, null, "3"]}
			top={hasDynamicHeaderBackground ? ["var(--chakra-sizes-headerHeightBase)", null, null, "0"] : "0"}
			left="0"
			width="100%"
			pointerEvents="none"
			overflow="hidden">
			<ContentWrapper
				py={["0", null]}
				px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}
				bg="gradients.breakingNews"
				position="relative"
				sx={{
					"&:after": {
						content: "''",
						display: "block",
						position: "absolute",
						top: "0",
						bottom: "0",
						left: `${isRtl ? "auto" : "calc( 100% - 1px )"}`,
						right: `${isRtl ? "calc( 100% - 1px )" : "auto"}`,
						bg: "secondary.yellow100",
						w: ["0", null, `calc((100vw - ${sizes.contentWrapperWidthMD}) / 2 + 1px)`],
					}
				}}>
				<Flex justifyContent="flex-end">
					<ChakraRouterLink
						as={breakingNewsLink ? "a" : "span"}
						to={breakingNewsLink}
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						h="2.625rem"
						ps={["3.75rem", null, "0"]}
						flexBasis={["100%", null, null, "calc(100% - 400px)"]}
						pointerEvents="auto">
						<ChakraText
							variant="footerBody"
							noOfLines={1}
							color={[hasDynamicHeaderBackground ? "primary.white" : colors.ribbon.color, null, null, "primary.text"]}
							display="-webkit-inline-box"
							sx={breakingNewsLink ? { ...animations(isRtl).underline } : {}}>
							{breakingNews.label && (
								<Box as="span" fontWeight="700" fontSize="2xs" textTransform="uppercase">{breakingNews.label.value}&nbsp;</Box>
							)}
							{breakingNews.text?.value}
						</ChakraText>
					</ChakraRouterLink>
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};
